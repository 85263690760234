import Header from "../../components/header/header.component";
import { FaShareAlt, FaQrcode, FaDownload, FaPhone, FaEnvelope, FaGlobe, FaMapMarkedAlt, FaBirthdayCake, FaFile } from "react-icons/fa";
import Footer from "../../components/footer/footer.component";
import QrCodePopUp from "../../components/popups/qrCodePopup/qrCodePopup";
import SharePopUp from "../../components/popups/sharePopup/sharePopup";
import { Row, Col, Avatar, Button, Image } from "antd";
import { UserOutlined } from '@ant-design/icons';
import image from "../../photos/sample-gantt-chart.png";
// import formDataa from '../../vcard.json';
import { saveAs } from 'file-saver';
import avatarImg from '../../photos/avatar.png';
import './devPage.css';
import { getUserDetail, imageLoad } from "../../service/api";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useToken from '../../service/useToken';

const DevPage = () => {
    const { id } = useParams();
    const num = 234;
    const [formDataa, setformDataa] = useState(null);
    const [email, setEmail] = useState([]);
    const [website, setWebsite] = useState([]);
    const [address, setAddress] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [imageData, setImageData] = useState(null);
    const { token } = useToken();
    let ext = id.split('.')[1]
    // donwload file
    const downloadFile = (obj) => {
        imageLoad(id, data => {
            var obj = data.data;
            let splt = obj.split("X-SOCIALPROFILE;CHARSET=UTF-8;")
            for (let i = 0; i <= splt.length; i++) {
                let val = "X-SOCIALPROFILE;CHARSET=UTF-8;"
                obj = obj.replace(val, "URL;")
            }
            console.log(obj)
            const file = new Blob([obj], {
                type: 'text/vcard; name="enesser.vcf"'
            });
            const a = document.createElement("a");
            a.href = URL.createObjectURL(file);
            a.download = "vcard.vcf";
            a.click();
        })
        // const blob = new Blob([obj], { type: 'text/vcard' });
        // debugger

    };

    // dwonload File
    useEffect(() => {
        if (id.split('.')[1] == 'vcf') {

            imageLoad(id.split('.')[0], data => {
                var obj = data.data;
                let splt = obj.split("X-SOCIALPROFILE;CHARSET=UTF-8;")
                for (let i = 0; i <= splt.length; i++) {
                    let val = "X-SOCIALPROFILE;CHARSET=UTF-8;"
                    obj = obj.replace(val, "URL;")
                }
                console.log(obj)
                const file = new Blob([obj], {
                    type: 'text/vcard; name="enesser.vcf"'
                });
                const a = document.createElement("a");
                a.href = URL.createObjectURL(file);
                a.download = "vcard.vcf";
                a.click();
            })
        } else {
            getUserDetail(id.split('.')[0], data => {
                setformDataa(data)
            });
        }

        // // formDataa.emails = JSON.parse(formDataa?.emails);
        // console.log(formDataa.emails);
    }, [])

    useEffect(() => {
        if (formDataa) {
            setEmail(formDataa?.emailArray ? JSON.parse(formDataa?.emailArray) : []);
            setWebsite(formDataa?.website ? JSON.parse(formDataa?.website) : []);
            setAddress(formDataa?.address ? JSON.parse(formDataa?.address) : []);
            setPhoneNumber(formDataa?.phoneNumber ? JSON.parse(formDataa?.phoneNumber) : []);
            // setImageData(formDataa?.image ? JSON.parse(formDataa?.image): null);
        }
    }, [formDataa])

    return (
        <>
            {ext == "json" && JSON.stringify(formDataa)}
            {ext == "png" && <div className="pngImage-qr"><Image preview={false} src={formDataa?.qrcode} width={320} ></Image></div>}
            {!ext && <div className="dev-content-page">
                <div>
                    <div style={{ padding: "0 20px" }}>
                        <Header />
                        <div className="dev-section">
                            <div className="dev-section-flex">
                                {/* <div className="dev-avatar">
                                    <img src={image} alt="" />
                                </div> */}

                                <div class="avatar info-div">
                                    <Avatar
                                        src={formDataa?.image ? `data:image/png;base64,${formDataa?.image}` : formDataa?.image}
                                        size={
                                            150
                                        }
                                        icon={<UserOutlined />}
                                        class="avatar__image"
                                    />
                                </div>
                                <div>
                                    <h2 style={{ fontSize: "2rem", marginBottom: "0.5rem", fontWeight: "500", marginTop: "0" }}>{formDataa?.suffix} {formDataa?.firstName} {formDataa?.lastName}</h2>
                                </div>
                                <div style={{ marginBottom: "1rem" }}>
                                    <div>{formDataa?.title}</div>
                                    <div>
                                        <h2 style={{ fontSize: "1.2rem", marginBottom: "0.5rem", fontWeight: "500", marginTop: "0" }}>{formDataa?.organization}</h2>

                                    </div>
                                    <div>{formDataa?.position}</div>
                                </div>
                            </div>
                            <div className="popups">
                                <div className="popup-flex">
                                    <div className="popup-button">
                                        {/* <label for="file-input" class="file-label"><FaQrcode /></label>
                                    <input id="file-input" type="file" class="file-input" /> */}
                                        <QrCodePopUp value={formDataa}></QrCodePopUp>
                                        <p>Scan</p>
                                    </div>
                                    <div className="popup-button share-qr-margin" style={{ marginLeft: "0.7rem" }}>
                                        {/* <label for="file-input" class="file-label"><FaShareAlt /></label>
                                    <input id="file-input" type="file" class="file-input" /> */}
                                        <SharePopUp />
                                        <p>Share</p>
                                    </div>
                                    <div className="popup-button " style={{ marginLeft: "0.5rem" }}>
                                        <Button className='button-share-qr' onClick={() => downloadFile(formDataa)} value={formDataa?._id}>
                                            <FaDownload />
                                        </Button>
                                        <p>Save</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            {phoneNumber.length > 0 && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaPhone className="icon-size" /></div>
                                        <div className="label-section">Phones</div>
                                    </Row>

                                </Col>
                                <Col xs={14} sm={17} offset={2} span={18} className="right-section">
                                    {phoneNumber.map(item =>
                                    (
                                        <div style={{ padding: "2px" }}>
                                            <a href={"tel:" + item.phone} className="clickable-links">{item.phone}</a>
                                        </div>
                                    ))}
                                    {/* <div>
                                    <a href="#" className="clickable-links">222222</a>
                                </div> */}
                                    <hr />
                                </Col>
                            </Row>}

                            {email.length > 0 && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaEnvelope className="icon-size" /></div>
                                        <div className="label-section">Emails</div>
                                    </Row>

                                </Col>
                                <Col xs={14} sm={17} offset={2} span={18} className="right-section" >
                                    {email.map(item =>
                                    (
                                        <div style={{ padding: "2px" }}>
                                            <a href={'mailto:' + item.email} className="clickable-links">{item.email}</a>
                                        </div>

                                    ))}
                                    <hr />
                                </Col>
                            </Row>}

                            {website.length > 0 && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaGlobe className="icon-size" /></div>
                                        <div className="label-section">Websites</div>
                                    </Row>

                                </Col>
                                <Col xs={14} sm={17} offset={2} span={18} className="right-section" >
                                    {website.map(item =>
                                    (
                                        <div style={{ padding: "2px" }}>
                                            <a href={item.webLink} className="clickable-links">{item.webLink}</a>
                                        </div>

                                    ))}
                                    <hr />
                                </Col>
                            </Row>
                            }

                            {address.length > 0 && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaMapMarkedAlt className="icon-size" /></div>
                                        <div className="label-section">Address</div>
                                    </Row>

                                </Col>

                                <Col xs={14} sm={17} offset={2} span={18} className="right-section" >
                                    {address.map(item =>
                                    (
                                        <div style={{ paddingTop: "5px" }}>
                                            <div>
                                                {item.addressName}
                                            </div>
                                            <div>
                                                {item.addressStreet}
                                            </div>
                                            <div>
                                                {item.addressSuite}
                                            </div>
                                            <div>
                                                {item.city}, {item.region}
                                            </div>
                                            <div>
                                                {item.country}, {item.zip}
                                            </div>
                                            <hr />
                                        </div>
                                    )

                                    )
                                    }
                                </Col>

                            </Row>}

                            {formDataa?.dob && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaBirthdayCake className="icon-size" /></div>
                                        <div className="label-section">Birthday</div>
                                    </Row>

                                </Col>
                                <Col xs={14} sm={17} offset={2} span={18} className="right-section">
                                    <div>
                                        {formDataa?.dob}
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            }

                            {formDataa?.note && <Row className="phone-section">
                                <Col xs={8} sm={5} span={4} className="left-section">
                                    <Row>
                                        <div className="icon-section"><FaFile className="icon-size" /></div>
                                        <div className="label-section">Note</div>
                                    </Row>

                                </Col>
                                <Col xs={14} sm={17} offset={2} span={18} className="right-section">
                                    <div>
                                        {formDataa?.note}
                                    </div>
                                </Col>
                            </Row>}
                        </div>

                        <div className="edit-create-footer">
                            Created on 2023-02-13
                            {/* {token ? <Link to={"/edit/" + formDataa?.hash} className="edit-card-link">Edit this card</Link> : ''} */}
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>}
        </>
    )
}

export default DevPage;