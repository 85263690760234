import Header from "../../components/header/header.component";
import Card from "../../components/card/card.component";
import FormComp from "../../components/form/form.component";
import Footer from "../../components/footer/footer.component";
import { Router, Routes, Route, useParams, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Row, Col, Avatar, Button, Input, Form, InputNumber, Select, notification } from "antd";
import { FaCamera, FaTimes } from "react-icons/fa";
import { UserOutlined } from '@ant-design/icons';
import { addForm, getUserDetail, updateCard } from "../../service/api";
import ForgotPassword from "../../components/popups/forgotPassPopup/forgotPassPopup";
import { useNavigate } from 'react-router-dom';



import AnotherPage from "../anotherpage";

import "../mainpage/mainPage.css";
import useToken from "../../service/useToken";

const EditPage = () => {
    const [imageData, setImageData] = useState();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [userEdit, setUserEdit] = useState({});
    const { id } = useParams();
    const [formDataa, setformDataa] = useState(null);
    const [loading, setLoading] = useState(false);

    const { token } = useToken();

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64 = btoa(event.target.result);
            debugger
            setImageData(base64);
        };
        reader.readAsBinaryString(file);
    };




    // imageeeee
    const { TextArea } = Input;
    const handleCancel = async () => {
        navigate('/card/' + formDataa.hash);

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };
    const handleSubmit = async (values) => {
        setLoading(true)
        console.log(values);
        values.address = values?.address ? JSON.stringify(values?.address) : null
        values.phoneNumber = values?.phoneNumber ? JSON.stringify(values?.phoneNumber) : null
        values.emailArray = values?.emailArray ? JSON.stringify(values?.emailArray) : null
        values.website = values?.website ? JSON.stringify(values?.website) : null
        values.image = imageData
        values.hash = formDataa.hash
        values._id = formDataa._id
        if (values._id) {
            await updateCard(values, token, data => {
                setLoading(false)

                if (data.status == 200) {
                    navigate('/card/' + values.hash);
                } else {
                    form.setFields([
                        {
                            name: 'password',
                            errors: ["The passwords don't match"],
                        }])
                    notification.error({
                        message: `Notification`,
                        description: data.message,
                        placement: 'topRight',
                    });
                }
            });

        }

    };
    useEffect(() => {
        getUserDetail(id, data => {
            setformDataa(data)
        });
    }, [])

    useEffect(() => {
        if (formDataa) {
            formDataa.emailArray = formDataa?.emailArray ? JSON.parse(formDataa?.emailArray) : null
            formDataa.phoneNumber = formDataa?.phoneNumber ? JSON.parse(formDataa?.phoneNumber) : null
            formDataa.address = formDataa?.address ? JSON.parse(formDataa?.address) : null
            formDataa.website = formDataa?.website ? JSON.parse(formDataa?.website) : null
            formDataa.title = formDataa?.title ? formDataa?.webstitleite : null
            formDataa.firstName = formDataa?.firstName ? formDataa?.firstName : null
            formDataa.lastName = formDataa?.lastName ? formDataa?.lastName : ''
            formDataa.organization = formDataa?.organization ? formDataa?.organization : ''
            formDataa.name = formDataa?.name ? formDataa?.name : null
            formDataa.suffix = formDataa?.suffix ? formDataa?.suffix : null
            formDataa.dob = formDataa?.dob ? formDataa?.suffix : null
            setImageData(formDataa?.image)
            form.setFieldsValue(formDataa)
        }
    }, [formDataa])

    const [jsonOutput, setJsonOutput] = useState('');

    return (
        <div className="main-content-page">
            <div>
                <div style={{ padding: "0 20px" }}>
                    <Header />
                    <Card />
                    {/* <FormComp /> */}
                    <div className="container">
                        <Form
                            name="addAds"
                            onFinish={handleSubmit}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                            form={form}
                        >
                            <Row className="flexx">
                                <Col span={4} xs={24} sm={6} md={6} className="label">
                                    <label htmlFor="">
                                        Card Info
                                    </label>
                                </Col>

                                <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name="name"
                                                style={{ marginBottom: "0" }}
                                                className="formItem-margins"

                                            >
                                                <Input placeholder="Card Name" className="info-input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <div>
                                <Row className="flexx">
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Authentication
                                        </label>
                                    </Col>
                                    <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                        <Row>
                                            <Col span={24} xs={24} md={24} >
                                                <Form.Item
                                                    name="email"
                                                    className=" formItem-margins"
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        },
                                                        // {
                                                        //     required: true,
                                                        //     message: 'A email is required if editing a card',
                                                        // },
                                                    ]}
                                                >
                                                    <Input placeholder="Email address" className="info-input" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} xs={24} md={12} >
                                                <Form.Item
                                                    name="password"
                                                    className="formItem-margins"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please Input password",
                                                //     },
                                                // ]}
                                                >
                                                    <Input.Password placeholder="Password" className="info-input" style={{ display: "flex" }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* <div className="primary ">
                                            <p>You must provide a password to edit this card.</p>
                                             <ForgotPassword></ForgotPassword> 
                                        </div> */}
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="flexx">
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Photo
                                        </label>
                                    </Col>
                                    <Col span={20} xs={24} sm={18} md={18}>
                                        <div class="avatar info-div">
                                            <Avatar
                                                src={`data:image/png;base64,${imageData}`}
                                                size={
                                                    150
                                                }
                                                icon={<UserOutlined />}
                                                class="avatar__image"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={18} offset={6} className="info-div camera-button remove-margin-xs">
                                        {/* <label htmlFor="image" name="image" class="file-label"><FaCamera /></label>
                                        <input id="file-input" type="file" name="image" onChange={handleFileInputChange} /> */}
                                        <label name="image" class="file-label-cam">
                                            <input id="file-input" type="file" name="image" onChange={handleFileInputChange} />
                                            <FaCamera className="span" />
                                        </label>
                                    </Col>
                                </Row>
                                {/* <ImageUpload></ImageUpload> */}
                            </div>

                            <div className="margin-contact">
                                <Row className="flexx">
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Contact
                                        </label>
                                    </Col>
                                    <Col span={20} xs={24} sm={18} md={18}>
                                        <Row className="info-div info-flex-xs">
                                            <Col span={12}>
                                                {
                                                    <Form.Item
                                                        name="title"
                                                        className="formItem-margins"
                                                    >
                                                        <Input className="info-input" placeholder="Title" />
                                                    </Form.Item>
                                                }
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="suffix"
                                                    className="formItem-margins"
                                                >
                                                    <Input className="info-input" placeholder="Suffix" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="info-div contact">
                                            <Col span={24}>
                                                <Form.Item
                                                    name="firstName"
                                                    className="formItem-margins"
                                                >
                                                    <Input placeholder="First name" className="info-input" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="info-div contact" style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="lastName"
                                                    className="formItem-margins"
                                                >
                                                    <Input className="info-input" placeholder="Last name" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>

                            <div>
                                <Row className="flexx">
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Birthday
                                        </label>
                                    </Col>
                                    <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                        <Form.Item
                                            name="dob"
                                            className="formItem-margins"
                                        >
                                            <Input className="info-input" placeholder="DD-MM-YYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="flexx">
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Occupation
                                        </label>
                                    </Col>

                                    <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="organization"
                                                    className="formItem-margins"
                                                >
                                                    <Input className="info-input" placeholder="Organization" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="contact">
                                                <Form.Item
                                                    name="position"
                                                    className="formItem-margins"
                                                >
                                                    <Input className="info-input" placeholder="Position" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Form.List name="phoneNumber">
                                    {(fields, { add, remove }) => (
                                        <Row>
                                            <Col span={24} >
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Row key={index}>
                                                            <Col span={4} xs={24} sm={6} md={6} className="label">
                                                                <label>{`Phone ${index + 1}`}:</label>
                                                                <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                            </Col>
                                                            <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                                                <Row>
                                                                    <Col span={4} xs={24} sm={5} md={4}>
                                                                        <Form.Item name={[index, "type"]}>
                                                                            <Select
                                                                                // className="info-input"
                                                                                placeholder="Select an option"

                                                                                name="type"
                                                                                // style={{
                                                                                //     width: 120,
                                                                                //     height: "calc(1.5em + 0.75rem + 2px)",
                                                                                // }}
                                                                                options={[
                                                                                    {
                                                                                        value: 'Main',
                                                                                        label: 'Main',
                                                                                    },
                                                                                    {
                                                                                        value: 'Home',
                                                                                        label: 'Home',
                                                                                    },
                                                                                    {
                                                                                        value: 'Work',
                                                                                        label: 'Work',
                                                                                    },
                                                                                    {
                                                                                        value: 'Cell',
                                                                                        label: 'Cell',
                                                                                    },
                                                                                    {
                                                                                        value: 'Fax',
                                                                                        label: 'Fax',
                                                                                    },
                                                                                    {
                                                                                        value: 'Office',
                                                                                        label: 'Office',
                                                                                    },
                                                                                    {
                                                                                        value: 'Other',
                                                                                        label: 'Other',
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Form.Item>

                                                                    </Col>
                                                                    <Col span={18} xs={24} sm={17} md={18}>
                                                                        <Form.Item className="formItem-margins input-number" name={[index, "phone"]}>
                                                                            <InputNumber className="info-input" placeholder="Number" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={2} xs={24} sm={2}>
                                                                        <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            </Col>
                                            <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                                                <Button type="link" className="btns-add" onClick={() => add()}>
                                                    add phone
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Form.List>

                                <Form.List name="emailArray">
                                    {(fieldEmail, { add, remove }) => (
                                        <Row>
                                            <Col span={24} >
                                                {fieldEmail.map((field, index) => (
                                                    <Row key={index}>
                                                        <Col span={4} xs={24} sm={6} md={6} className="label">
                                                            <label className="label">{`Email ${index + 1}`}:</label>
                                                            <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                        </Col>
                                                        <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                                            <Row>
                                                                <Col span={4} xs={24} sm={5} md={4}>

                                                                    <Form.Item className="formItem-margins" name={[index, "type"]}>
                                                                        <Select
                                                                            // className="info-input"
                                                                            placeholder="Select an option"
                                                                            // value={inputField.name}
                                                                            name="type"
                                                                            // style={{
                                                                            //     width: 120,
                                                                            //     height: "calc(1.5em + 0.75rem + 2px)",
                                                                            // }}

                                                                            options={[
                                                                                {
                                                                                    value: 'Home',
                                                                                    label: 'Home',
                                                                                },
                                                                                {
                                                                                    value: 'Personal',
                                                                                    label: 'Personal',
                                                                                },
                                                                                {
                                                                                    value: 'Work',
                                                                                    label: 'Work',
                                                                                },
                                                                                {
                                                                                    value: 'Other',
                                                                                    label: 'Other',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={18} xs={24} sm={17} md={18}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "email"]}
                                                                        rules={[
                                                                            {
                                                                                type: 'email',
                                                                                message: 'The input is not valid E-mail!',
                                                                            },
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input your E-mail!',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Email" className="info-input" />
                                                                    </Form.Item>

                                                                </Col>
                                                                <Col span={2} xs={24} sm={2}>
                                                                    <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                            <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                                                <Button type="link" className="btns-add" onClick={() => add()}>
                                                    add email
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Form.List>

                                <Form.List name="website">
                                    {(fieldWeb, { add, remove }) => (
                                        <Row>
                                            <Col span={24} >
                                                {fieldWeb.map((field, index) => (
                                                    <Row key={index}>
                                                        <Col span={4} xs={24} sm={6} md={6} className="label">
                                                            <label className="label">{`Website ${index + 1}`}:</label>
                                                            <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                        </Col>
                                                        <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                                            <Row>
                                                                <Col span={4} xs={24} sm={5} md={4}>
                                                                    <Form.Item className="formItem-margins" name={[index, "type"]}>
                                                                        {/* <Select
                                                                            // className="info-input"
                                                                            placeholder="Select an option"
                                                                            // value={inputField.name}
                                                                            name="type"
                                                                            // style={{
                                                                            //     width: 120,
                                                                            //     height: "calc(1.5em + 0.75rem + 2px)",
                                                                            // }}

                                                                            options={[
                                                                                {
                                                                                    value: 'home',
                                                                                    label: 'Home',
                                                                                },
                                                                                {
                                                                                    value: 'work',
                                                                                    label: 'Work',
                                                                                },
                                                                                {
                                                                                    value: 'other',
                                                                                    label: 'Other',
                                                                                },
                                                                            ]}
                                                                        /> */}
                                                                        <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Type" className="info-input" />

                                                                    </Form.Item>

                                                                </Col>
                                                                <Col span={18} xs={24} sm={17} md={18}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "webLink"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                            },
                                                                            {
                                                                                type: 'url',
                                                                                warningOnly: true,
                                                                            },
                                                                            {
                                                                                type: 'string',
                                                                                min: 6,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Link URL" className="info-input" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2} xs={24} sm={2}>
                                                                    <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                            <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                                                <Button type="link" className="btns-add" onClick={() => add()}>
                                                    add website
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Form.List>

                                <Form.List name="address">
                                    {(fieldAddress, { add, remove }) => (
                                        <Row>
                                            <Col span={24} >
                                                {fieldAddress.map((field, index) => (
                                                    <Row key={index}>
                                                        <Col span={4} xs={24} sm={6} md={6} className="label">
                                                            <label className="label">{`Address ${index + 1}`}:</label>
                                                            <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                        </Col>
                                                        <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                                            <Row>
                                                                <Col span={4} xs={24} sm={5} md={4}>
                                                                    <Form.Item className="formItem-margins" name={[index, "type"]}>
                                                                        <Select
                                                                            // className="info-input"
                                                                            placeholder="Select an option"
                                                                            name="type" // value={inputField.name}
                                                                            // style={{
                                                                            //     width: 120,
                                                                            //     height: "calc(1.5em + 0.75rem + 2px)",
                                                                            // }}

                                                                            options={[
                                                                                {
                                                                                    value: 'Home',
                                                                                    label: 'Home',
                                                                                },
                                                                                {
                                                                                    value: 'Mailing',
                                                                                    label: 'Mailing',
                                                                                },
                                                                                {
                                                                                    value: 'Work',
                                                                                    label: 'Work',
                                                                                },
                                                                                {
                                                                                    value: 'Other',
                                                                                    label: 'Other',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={18} xs={24} sm={17} md={18}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "addressName"]}
                                                                    >
                                                                        <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Name" className="info-input" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2} xs={24} sm={4} md={2}>
                                                                    <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                                                                </Col>
                                                                <Col span={22} >
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "addressStreet"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="Address Streed" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={22}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "addressSuite"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="Apt, Suit, Bldg." />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "city"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="City" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "region"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="Region" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={14}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "country"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="Country" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        className="formItem-margins"
                                                                        name={[index, "zip"]}
                                                                    >
                                                                        <Input className="info-input" placeholder="Zip/Postal Code" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                            <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                                                <Button type="link" className="btns-add" onClick={() => add()}>
                                                    add address
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Form.List>
                            </div >

                            <div>
                                <Row className="flexx" style={{ marginTop: "1rem" }}>
                                    <Col span={4} xs={24} sm={6} md={6} className="label">
                                        <label htmlFor="" className="label">
                                            Note
                                        </label>
                                    </Col>

                                    <Col span={20} xs={24} sm={18} md={18} className="info-div">
                                        <Form.Item name="note">
                                            <TextArea rows={5}></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="flexx">
                                    <Col span={4} offset={6} className="info-div info-create-card remove-margin-xs">
                                        <Button type="primary" htmlType="submit" loading={loading}>Update Card</Button>
                                    </Col>
                                    <Col span={4} className="info-div info-create-card remove-margin-xs">
                                        <Button type="default" onClick={handleCancel} >Cancel</Button>
                                    </Col>
                                </Row>
                                <Row className="flexx">
                                    <Col span={18} offset={6} className="info-div remove-margin-xs">
                                        <p className="believe-vcard">
                                            Contact information submitted will be made available using the
                                            link to your Ember-Link. We will not actively share your
                                            information with other parties.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Form >
                    </div >
                    {/* <p>{jsonOutput}</p> */}

                    <Footer />
                </div>
            </div >

        </div >
    )
}

export default EditPage;