import { useState } from 'react';
import jwt_decode from "jwt-decode";

export default function useToken() {
    const getToken = () => {
        const tokenData = JSON.parse(localStorage.getItem('@userdata'));
        const userToken = localStorage.getItem('@userToken');
        return tokenData ? tokenData : null;
    };

    const checkTokenExpirey = () => {
        const userToken = localStorage.getItem('@userToken');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            decodedToken.exp = new Date(decodedToken.exp * 1000);
            if (decodedToken.exp < new Date()) {
                localStorage.clear()
            }
            return decodedToken.exp > new Date() ? userToken : null;

        } else {
            return null
        }
    };

    const [token, setToken] = useState(localStorage.getItem('@userToken'));
    const [userLoginObject, setUserLoginObject] = useState(getToken() ? getToken() : null);

    const saveToken = userToken => {
        // debugger
        localStorage.setItem('@userdata', JSON.stringify(userToken));
        localStorage.setItem('@userToken', userToken.token);

        setToken(localStorage.getItem('@userToken'));
    };

    const removeToken = () => {
        localStorage.removeItem("@userdata")
        localStorage.removeItem("@userToken")
        localStorage.clear()
        setToken(null)
    }

    return {
        saveLoginUser: saveToken,
        token,
        removeToken,
        userLoginObject,
    }
}