import { FaCamera, FaTimes } from "react-icons/fa";
import { UserOutlined } from '@ant-design/icons';

import { Row, Col, Avatar, Button, Input, Form, InputNumber, Select, message, notification, Modal } from "antd";
import ImageUpload from "../imageupload/imageUpload";
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import "../form/form.css";
import { addForm } from "../../service/api";
import { useToken } from "antd/es/theme/internal";




const FormComp = () => {
  // imageeee
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useToken();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(null);




  const [messageApi] = message.useMessage();
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64 = btoa(event.target.result);
      setImageData(base64);
    };
    reader.readAsBinaryString(file);
  };



  // imageeeee
  const { TextArea } = Input;

  const checkUserPassword = async (val) => {
    setModel(val)
    if (!val.email || !val.password) {
      setOpen(true)
    } else {
      handleSubmit(val)
    }
  }



  const setPasswordLatter = () => {
    setOpen(false);
    handleSubmit(model)
  };

  const handleSubmit = async (values) => {

    setLoading(true)
    console.log(values);
    values.address = values?.address ? JSON.stringify(values?.address) : null
    values.phoneNumber = values?.phoneNumber ? JSON.stringify(values?.phoneNumber) : null
    values.emailArray = values?.emailArray ? JSON.stringify(values?.emailArray) : null
    values.website = values?.website ? JSON.stringify(values?.website) : null
    values.image = imageData
    try {

      await addForm(values, res => {
        let data = res.data
        if (!res.status) {

          notification.error({
            message: `Notification`,
            description: res.message,
            placement: 'topRight',
          });
        } else {
          setLoading(true)
          navigate('/card/' + data.hash);
        }
      });
    } catch (error) {
      debugger
      messageApi.open({
        type: 'error',
        content: '',
      });
      console.log('Error while call add card api', error);
    }
  };

  const setPassword = () => {
    setOpen(false);
  };

  return (
    <div className="container">
      <Form
        name="addAds"
        onFinish={checkUserPassword}
        autoComplete="off"
        loading={loading}
        layout='vertical'
      >
        <Row className="flexx">
          <Col span={4} xs={24} sm={6} md={6} className="label">
            <label htmlFor="">
              Card Info
            </label>
          </Col>

          <Col span={20} xs={24} sm={18} md={18} className="info-div">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  style={{ marginBottom: "0" }}
                  className="formItem-margins"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Input Title",
                //   },
                // ]}
                >
                  <Input placeholder="Card name" className="info-input" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div>
          <Row className="flexx">
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Authentication
              </label>
            </Col>
            <Col span={20} xs={24} sm={18} md={18} className="info-div">
              <Row>
                <Col span={12} xs={24} md={12} >
                  <Form.Item
                    name="email"
                    className="formItem-margins"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      // {
                      //   required: true,
                      //   message: 'Please input your E-mail!',
                      // },
                    ]}
                  >
                    <Input placeholder="Email address" className="info-input" />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} >

                  <Form.Item
                    name="password"
                    className="formItem-margins"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Input password",
                  //   },
                  // ]}
                  >
                    <Input.Password placeholder="Password" className="info-input" style={{ display: "flex" }} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="primary ">
                <p>Setting a password allows you to edit your card later.</p>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row className="flexx">
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Photo
              </label>
            </Col>
            <Col span={20} xs={24} sm={18} md={18}>
              {imageData && (
                <div class="avatar info-div">
                  <Avatar
                    src={`data:image/png;base64,${imageData}`}
                    size={
                      150
                    }
                    icon={<UserOutlined />}
                    class="avatar__image"
                    alt="fff"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={18} offset={6} className="info-div camera-button remove-margin-xs">
              <label name="image" class="file-label-cam">
                <input id="file-input" type="file" name="image" onChange={handleFileInputChange} />
                <FaCamera className="span" />
              </label>

            </Col>
          </Row>
          {/* <ImageUpload></ImageUpload> */}

        </div>

        <div className="margin-contact">
          <Row className="flexx">
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Contact
              </label>
            </Col>
            <Col span={20} xs={24} sm={18} md={18}>
              <Row className="info-div info-flex-xs">
                <Col span={12}>
                  <Form.Item
                    name="title"
                    className="formItem-margins"
                  >
                    <Input placeholder="Title" className="info-input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="suffix"
                    className="formItem-margins"
                  >
                    <Input placeholder="Suffix" className="info-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="info-div contact">
                <Col span={24}>
                  <Form.Item
                    name="firstName"
                    className="formItem-margins"
                    rules={[
                      {
                        required: true,
                        message: "You must supply at least a first name",
                      },
                    ]}
                  >
                    <Input placeholder="First name" className="info-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="info-div contact" style={{ marginBottom: 0 }}>
                <Col span={24}>
                  <Form.Item
                    name="lastName"
                    className="formItem-margins"
                  >
                    <Input placeholder="Last name" className="info-input" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>

        <div>
          <Row className="flexx">
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Birthday
              </label>
            </Col>
            <Col span={20} xs={24} sm={18} md={18} className="info-div">
              <Form.Item
                name="dob"
                className="formItem-margins"
              >
                <Input placeholder="DD-MM-YYYY" className="info-input" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div>
          <Row className="flexx">
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Occupation
              </label>
            </Col>

            <Col span={20} xs={24} sm={18} md={18} className="info-div">
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="organization"
                    className="formItem-margins"
                  >
                    <Input placeholder="Organization name" className="info-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="contact">
                  <Form.Item
                    name="position"
                    className="formItem-margins"
                  >
                    <Input placeholder="Position title" className="info-input" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div>
          <Form.List name="phoneNumber">
            {(fields, { add, remove }) => (
              <Row>
                <Col span={24}>
                  <>
                    {fields.map((field, index) => (
                      <Row key={index}>
                        <Col span={4} xs={24} sm={6} md={6} className="label">
                          <label >{`Phone ${index + 1}`}:</label>
                          <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                        </Col>
                        <Col span={20} xs={24} sm={18} md={18} className="info-div">
                          <Row>
                            <Col span={4} xs={24} sm={5} md={4}>
                              <Form.Item className="formItem-margins" name={[index, "type"]}>
                                <Select
                                  placeholder="Select an option"
                                  name="type"
                                  // style={{
                                  //   width: 120,
                                  //   height: "calc(1.5em + 0.75rem + 2px)",
                                  // }}
                                  options={[
                                    {
                                      value: 'Main',
                                      label: 'Main',
                                    },
                                    {
                                      value: 'Home',
                                      label: 'Home',
                                    },
                                    {
                                      value: 'work',
                                      label: 'Work',
                                    },
                                    {
                                      value: 'Cell',
                                      label: 'Cell',
                                    },
                                    {
                                      value: 'Fax',
                                      label: 'Fax',
                                    },
                                    {
                                      value: 'Office',
                                      label: 'Office',
                                    },
                                    {
                                      value: 'Other',
                                      label: 'Other',
                                    },
                                  ]}
                                />
                              </Form.Item>

                            </Col>
                            <Col span={18} xs={24} sm={17} md={18}>
                              <Form.Item className="formItem-margins input-number" name={[index, "phone"]}>
                                <InputNumber placeholder="Number" className="info-input" />
                              </Form.Item>
                            </Col>
                            <Col span={2} xs={24} sm={2}>
                              <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </>
                </Col>
                <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                  <Button type="link" className="btns-add" onClick={() => add()}>
                    add phone
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>

          <Form.List name="emailArray">
            {(fieldEmail, { add, remove }) => (
              <Row>
                <Col span={24}  >
                  {fieldEmail.map((field, index) => (
                    <Row key={index} style={{ marginTop: "1rem" }}>
                      <Col span={4} xs={24} sm={6} md={6} className="label">
                        <label className="label">{`Email ${index + 1}`}:</label>
                        <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                      </Col>
                      <Col span={20} xs={24} sm={18} md={18} className="info-div">
                        <Row>
                          <Col span={4} xs={24} sm={5} md={4}>
                            <Form.Item className="formItem-margins" name={[index, "type"]}>
                              <Select
                                // className="info-input"
                                placeholder="Select an option"
                                // value={inputField.name}
                                name="type"
                                // style={{
                                //   width: 120,
                                //   height: "calc(1.5em + 0.75rem + 2px)",
                                // }}

                                options={[
                                  {
                                    value: 'Home',
                                    label: 'Home',
                                  },
                                  {
                                    value: 'Personal',
                                    label: 'Personal',
                                  },
                                  {
                                    value: 'Work',
                                    label: 'Work',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={18} xs={24} sm={17} md={18}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "email"]}
                              rules={[
                                {
                                  type: 'email',
                                  message: 'The input is not valid E-mail!',
                                },
                                {
                                  required: true,
                                  message: 'Please input your E-mail!',
                                },
                              ]}
                            >
                              <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Address" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={2} xs={24} sm={2}>
                            <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                  <Button type="link" className="btns-add" onClick={() => add()}>
                    add email
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>

          <Form.List name="website">
            {(fieldWeb, { add, remove }) => (
              <Row>
                <Col span={24} >
                  {fieldWeb.map((field, index) => (
                    <Row key={index} style={{ marginTop: "1rem" }}>
                      <Col span={4} xs={24} sm={6} md={6} className="label">
                        <label className="label">{`Website ${index + 1}`}:</label>
                        <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                      </Col>
                      <Col span={20} xs={24} sm={18} md={18} className="info-div">
                        <Row>
                          <Col span={4} xs={24} sm={5} md={4}>
                            <Form.Item className="formItem-margins" name={[index, "type"]}>
                              {/* <Select
                                // className="info-input"
                                placeholder="Select an option"
                                // value={inputField.name}
                                name="type"
                                // style={{
                                //   width: 120,
                                //   height: "calc(1.5em + 0.75rem + 2px)",
                                // }}

                                options={[
                                  {
                                    value: 'home',
                                    label: 'Home',
                                  },
                                  {
                                    value: 'work',
                                    label: 'Work',
                                  },
                                  {
                                    value: 'other',
                                    label: 'Other',
                                  },
                                ]}
                              /> */}
                              <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Type" className="info-input" />
                            </Form.Item>

                          </Col>
                          <Col span={18} xs={24} sm={17} md={18}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "webLink"]}
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  type: 'url',
                                  warningOnly: true,
                                },
                                {
                                  type: 'string',
                                  min: 6,
                                },
                              ]}
                            >
                              <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Link URL" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={2} xs={24} sm={2}>
                            <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                  <Button type="link" className="btns-add" onClick={() => add()}>
                    add website
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>

          <Form.List name="address">
            {(fieldAddress, { add, remove }) => (
              <Row>
                <Col span={24} >
                  {fieldAddress.map((field, index) => (
                    <Row key={index} style={{ marginTop: "1rem" }}>
                      <Col span={4} xs={24} sm={6} md={6} className="label">
                        <label className="label">{`Address ${index + 1}`}:</label>
                        <button className="delete-btn delete-btn-visibility" type="button" onClick={() => remove(index)}><FaTimes /></button>
                      </Col>
                      <Col span={20} xs={24} sm={18} md={18} className="info-div">
                        <Row>
                          <Col span={4} xs={24} sm={5} md={4}>
                            <Form.Item className="formItem-margins" name={[index, "type"]}>
                              <Select
                                // className="info-input"
                                placeholder="Select an option"
                                name="type" // value={inputField.name}
                                // style={{
                                //   width: 120,
                                //   height: "calc(1.5em + 0.75rem + 2px)",
                                // }}

                                options={[
                                  {
                                    value: 'Home',
                                    label: 'Home',
                                  },
                                  {
                                    value: 'Mailing',
                                    label: 'Mailing',
                                  },
                                  {
                                    value: 'Work',
                                    label: 'Work',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  },
                                ]}
                              />
                            </Form.Item>

                          </Col>
                          <Col span={18} xs={24} sm={17} md={18}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "addressName"]}
                            >
                              <Input style={{ padding: "0.55rem 0.75rem" }} placeholder="Name" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={2} xs={24} sm={4} md={2}>
                            <button className="delete-btn delete-btn-visibility2" type="button" onClick={() => remove(index)}><FaTimes /></button>
                          </Col>
                          <Col span={22} >
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "addressStreet"]}
                            >
                              <Input placeholder="Street Address" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={22}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "addressSuite"]}
                            >
                              <Input placeholder="Apt, Suite, Bldg." className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "city"]}
                            >
                              <Input placeholder="City" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "region"]}
                            >
                              <Input placeholder="Region" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={14}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "country"]}
                            >
                              <Input placeholder="Country" className="info-input" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className="formItem-margins"
                              name={[index, "zip"]}
                            >
                              <Input placeholder="Zip/Postal Code" className="info-input" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col span={18} offset={6} className="info-div-add remove-margin-xs">
                  <Button type="link" className="btns-add" onClick={() => add()}>
                    add address
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>
        </div >

        <div>
          <Row className="flexx" style={{ marginTop: "1rem" }}>
            <Col span={4} xs={24} sm={6} md={6} className="label">
              <label htmlFor="" className="label">
                Note
              </label>
            </Col>

            <Col span={20} xs={24} sm={18} md={18} className="info-div">
              <Form.Item
                name="note"
                className="formItem-margins"
              >
                <TextArea rows={5}></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div>
          <Row className="flexx">
            <Col span={18} offset={6} className="info-div info-create-card remove-margin-xs">
              <Button htmlType="submit" type="primary" loading={loading}>Create Card</Button>
            </Col>
          </Row>
          <Modal
            title="Create vCard without password?"
            open={open}
            onOk={setPassword}
            onCancel={setPasswordLatter}

            // footer={null}
            footer={[
              <Button onClick={setPasswordLatter} className="mr-2" style={{ color: "white", backgroundColor: "#5a6268", marginRight: "5px" }}>
                I'll set one later
              </Button>,
              <Button type="primary" onClick={setPassword} >
                Set password now
              </Button>
            ]}
          >
            <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
              <Col span={24} className="">
                <label>  You are about to create a card without an account. You will able to set one later, but so will someone else if they want.
                </label>
              </Col>
            </Row>
          </Modal>
          <Row className="flexx">
            <Col span={18} offset={6} className="info-div remove-margin-xs">
              <p className="believe-vcard">
                Contact information submitted will be made available using the
                link to your vCard. We will not actively share your
                information with other parties.
              </p>
            </Col>
          </Row>
        </div>
      </Form >

    </div >
  );
};
export default FormComp;
