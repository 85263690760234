import { useState, useEffect } from 'react';
import { Input, Button, message, Row, Col } from 'antd';
import './copylinks.css';
import formDataa from '../../../vcard.json';
import { useParams } from 'react-router';
import { getUserDetail } from "../../../service/api";
const { REACT_APP_BASE_URL_FrontEnd } = process.env
// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const CopyLinks = () => {
  const [formDataa, setformDataa] = useState(null);

  const [inputValue1, setInputValue1] = useState(null);
  const [inputValue2, setInputValue2] = useState(null);
  const [inputValue3, setInputValue3] = useState(null);
  const [inputValue4, setInputValue4] = useState(null);
  const { id } = useParams()
  const baseUrl = REACT_APP_BASE_URL_FrontEnd + "card/";
  // let ext = id.split('.')[""]
  const urlJson = `${baseUrl}${id}.json`;
  const urlPng = `${baseUrl}${id}.png`;
  const urlVcf = `${baseUrl}${id}.vcf`;
  const url = `${baseUrl}${id}`;



  //   const handleInputChange1 = (event) => {
  //     setInputValue1(event.target.value);
  //   };

  //   const handleInputChange2 = (event) => {
  //     setInputValue2(event.target.value);
  //   };

  //   const handleInputChange3 = (event) => {
  //     setInputValue3(event.target.value);
  //   };

  const copyToClipboard = async (value) => {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.success('Text copied to clipboard');
  };

  const handleCopyClick2 = () => {

    navigator.clipboard.writeText(urlPng)
      .then(() => {
        message.success('Text copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy text to clipboard');
      });
  };

  const handleCopyClick3 = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        message.success('Text copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy text to clipboard');
      });
  };
  const handleCopyClick4 = () => {
    navigator.clipboard.writeText(urlJson)
      .then(() => {
        message.success('Text copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy text to clipboard');
      });
  };
  return (
    <>

      {/* {ext == "png" && <Image preview={false} src={formDataa?.qrcode} width={200}></Image>} */}
      <div className='copyLinks'>
        <label className='copylinks-labels' htmlFor="">Web URL</label>
        <Row gutter={16}>
          <Col span={20} xs={18} sm={20} md={20}>
            <Input className='copylinks-input' id="url" disabled value={url} />
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={() => copyToClipboard(url)}>Copy</Button>
          </Col>
        </Row>
      </div>
      <div className='copyLinks'>
        <label className='copylinks-labels' htmlFor="">PNG Code</label>
        <Row gutter={16}>
          <Col span={20} xs={18} sm={20} md={20}>
            <Input className='copylinks-input' disabled value={urlPng} />
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={() => copyToClipboard(urlPng)}>Copy</Button>
          </Col>
        </Row>
      </div>
      <div className='copyLinks'>
        <label className='copylinks-labels' htmlFor="">VCF File</label>
        <Row gutter={16}>
          <Col span={20} xs={18} sm={20} md={20}>
            <Input className='copylinks-input' disabled value={urlVcf} />
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={() => copyToClipboard(urlVcf)}>Copy</Button>
          </Col>
        </Row>
      </div>
      <div className='copyLinks'>
        <label className='copylinks-labels' htmlFor="">JSON</label>
        <Row gutter={16}>
          <Col span={20} xs={18} sm={20} md={20}>
            <Input className='copylinks-input' disabled value={urlJson} />
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={() => copyToClipboard(urlJson )}>Copy</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CopyLinks;