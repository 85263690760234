import { Button, Modal, Image } from 'antd';
import { useState } from 'react';
import ExampleImg from '../../photos/websites_create@2x.png';
import ResultImg from '../../photos/websites@2x.png';
import Example2Img from '../../photos/phones_create@2x.png';
import Result2Img from '../../photos/phones@2x.png';
import '../card/card.css';

const CardPopupModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setTimeout(() => {
      setOpen(false);
    });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Button className='optimise-vcard-btn' type="primary" onClick={showModal}>
        How to optimise your Ember-Link
      </Button>
      <Modal
        open={open}
        title="How to optimize your  Ember-Link"
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>
        }
      >
        <div className='card-popup-modal-content'>
            <h1>Set up authentication</h1>
            <p>By providing this information you will easily be able to come back and update your contact information when it changes. You will not have to change the link to your details.</p>
            <h1>Use websites to supplement your Ember-Link</h1>
            <p>We magically detect some websites and display them differently on your web Ember-Link. For example, if you have a Twitter, LinkedIn or Facebook account, simply add the links to your respective profiles on your Ember-Link and they will show up in a way that stands out.</p>
            <h3>Example</h3>
            <p><Image src={ExampleImg}></Image></p>
            <h3>Result</h3>
            <p><Image width={154} height={137} src={ResultImg}></Image></p>
            <h1>Use custom identifier for phone numbers</h1>
            <p>We currently support "whatsapp", "skype" and "imessage" prefixes to your phone numbers. Using these will show these methods of contact in a special way and provide the visitor the ability to click on the link to call you directly from their desktop or mobile device.</p>
            <h3>Example</h3>
            <p><Image src={Example2Img}></Image></p>
            <h3>Result</h3>
            <p><Image width={163} height={132} src={Result2Img}></Image></p>
            <h1>Make Ember-Link in the language of your audience</h1>
            <p>When creating a Ember-Link, change the language to the one you are expecting to distribute to people. If you're dealing with contact details in multiple languages, then it is advised to create multiple cards in order to stay connected with people in the language they speak.</p>
            <h1>Paste your Ember-Link  on social media</h1>
            <p>Some tools support special rendering of links, such as Facebook, Twitter or Slack. When pasting your link there, it will show a version of your Ember-Link that will look much better than a simple link.</p>
        </div>
      </Modal>
    </>
  );
}
export default CardPopupModal;