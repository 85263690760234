import CardPopupModal from './cardPopupModal';
import '../card/card.css';

const Card = () => {
    return(
        <div class="card">
            <div style={{display:"inline-block"}}>
                <p>At  ember-link you can create an online business card and share it with your contacts.</p>
                <p>It is an easy 2 step process:</p>
                <ol>
                    <li>Complete the form below with your contact information.</li>
                    <li>Copy your card link or QR-code and share it.</li>
                </ol>
                <p>The link and QR-code are fully embeddable in your website or email. Simply download the file or image and include it wherever you like.</p>
                <p>If you wish to keep your card information up to date then be sure to supply an email and password.</p>
                <p><CardPopupModal /></p>
            </div>
            
        </div>
    )
}
export default Card;