import Header from "../../components/header/header.component";
import Card from "../../components/card/card.component";
import FormComp from "../../components/form/form.component";
import Footer from "../../components/footer/footer.component";
import { Router, Routes, Route } from 'react-router-dom';
import { useState } from "react";
import AnotherPage from "../anotherpage";

import "./mainPage.css";

const MainPage = () => {
    const [jsonOutput, setJsonOutput] = useState('');

    return(
        <div className="main-content-page">
            <div>
                <div style={{padding:"0 20px"}}>
                    <Header />
                    <Card />
                    <FormComp />
                    <p>{jsonOutput}</p>
                    <Footer />
                </div>
            </div>
            
        </div>
    )
}

export default MainPage;