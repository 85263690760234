import { Button, Modal, Card, QRCode } from 'antd';
import { FaQrcode, FaShareAlt } from 'react-icons/fa';
import { useState } from 'react';
// import QrCode from './qrCode';
import CopyLinks from './copylinks';
import '../qrCodePopup/modal.css';
import { useParams } from 'react-router';
// const ModalQR = () => {
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   const showModal = () => {
//     setOpen(true);
//   };
//   const handleOk = () => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       setOpen(false);
//     }, 3000);
//   };
//   const handleCancel = () => {
//     setOpen(false);
//   };
//   return (
//     <>
//       <Button type="primary" className="qr-btn-style" icon={<FaQrcode />}  onClick={showModal}></Button>
//       <Modal
//         open={open}
//         title="Sacn QR Code"
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={
//           <Button className='close-btn-qr' key="back" onClick={handleCancel}>
//             Close
//           </Button>
//         }
//       >
//         <Card className='popup-card'>
//             <QrCode />
//         </Card>
//       </Modal>
//     </>
//   );
// };

const ModalShare = () =>{
  const [modal2Visible, setModal2Visible] = useState(false);
 
  return(
    <div >
      <Button className='button-share-qr' onClick={() => setModal2Visible(true)} icon={<FaShareAlt />}></Button>
      <Modal
        title="Share"
        visible={modal2Visible}
        onCancel={() => setModal2Visible(false)}
        footer={
            <Button className='close-btn-qr' key="back" onClick={() => setModal2Visible(false)}>
                Close
            </Button>
        }
      >
        <CopyLinks />
      </Modal>
    </div>

  )
}
export default ModalShare;