import React, { useState } from 'react';
import '../dropdown/dropdown.css'
const options = [
  { value: 'English', label: 'English' },
  { value: 'Chinese', label: 'Chinese' },
];

function DropdownMenu() {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  function handleChange(event) {
    setSelectedOption(options.find(o => o.value === event.target.value));
  }

  return (
    <select className='create-btn adjust' value={selectedOption.value} onChange={handleChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default DropdownMenu;