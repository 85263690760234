import React, { useState } from 'react';
import { Button, Modal, Row, Col, Form, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { forgotPassword, resetPassword } from '../../../service/api';



const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [isDataVisible, setIsDataVisible] = useState(false);
  const [nestedVisible, setNestedVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (values) => {
    console.log(values)
    setConfirmLoading(true);
    forgotPassword(values, data => {
      console.log(data)
      setOpen(false);
      setConfirmLoading(false);
      setNestedVisible(true)
      messageApi.open({
        type: 'success',
        content: 'E-mail sent to your mailbox',
      });
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 5000);
    })

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleNestedOk = (values) => {
    setConfirmLoading(true);
    resetPassword(values, data => {
      console.log(data)
      setConfirmLoading(false);
      setNestedVisible(false);
      messageApi.open({
        type: 'success',
        content: 'Token Verified',
      });
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 3000);
    })

  };

  const handleNestedCancel = () => {
    setNestedVisible(false);
  };

  return (
    <>
    {contextHolder}
      <Link type="primary" onClick={showModal}>
        Forgot Password?
      </Link>
      <Modal
        title="Request password recovery token?"
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}

      >
        <>
          <p>You will receive a password recovery token to the email you specify below if it matches the one we have on record.</p>
          <Form
            name="addAds"
            layout='vertical'
            onFinish={handleOk}
          >
            <Row style={{ alignItems: "center" }}>
              <Col span={6} className="label">
                <label htmlFor="" style={{ paddingLeft: "0", paddingRight: "0" }} className="label">
                  Recovery Email
                </label>
              </Col>
              <Col span={18} className="info-div">
                <Form.Item
                  name="email"
                  className="formItem-margins"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input placeholder="Email address" className="info-input" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ textAlign: "right", padding: "10px", borderTop: "1px solid #ccc", marginTop: "15px" }}>
              <Button onClick={handleCancel} className="mr-2" style={{ color: "white", backgroundColor: "#5a6268", marginRight: "5px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                Submit
              </Button>
              {/* <Button type="primary" htmlType="submit" className='ant-btn-44'>Save</Button> */}
            </div>
          </Form>
        </>
      </Modal>


      <Modal
        confirmLoading={confirmLoading}
        title="Reset password"
        open={nestedVisible}
        onCancel={handleNestedCancel}
        footer={false}

      >
        <Form
          name="addAds"
          layout='vertical'
          onFinish={handleNestedOk}
        >
          <div>
            <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
              <Col span={6} className="label">
                <label htmlFor="" style={{ paddingLeft: "0", paddingRight: "0" }} className="label">
                  Token
                </label>
              </Col>
              <Col span={18} className="info-div">
                <Form.Item
                  name="token"
                  className="formItem-margins"
                  rules={[
                    {
                      required: true,
                      message: 'Please input token sent to your email!',
                    },
                  ]}
                >
                  <Input placeholder="Token" className="info-input" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ alignItems: "center" }}>
              <Col span={6} className="label">
                <label htmlFor="" style={{ paddingLeft: "0", paddingRight: "0" }} className="label">
                  New Password
                </label>
              </Col>
              <Col span={18} className="info-div">
                <Form.Item
                  name="password"
                  className="formItem-margins"
                  rules={[
                    {
                      required: true,
                      message: "Please Input password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" className="info-input" style={{ display: "flex" }} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ textAlign: "right", padding: "10px", borderTop: "1px solid #ccc", marginTop: "15px" }}>

            <Button key="back" onClick={handleCancel} style={{ color: "white", backgroundColor: "#5a6268" }}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" htmlType='submit' loading={confirmLoading} onClick={handleOk}>
              Submit
            </Button>
          </div>

        </Form>
      </Modal>
    </>
  );
};

export default ForgotPassword;