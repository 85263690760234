import '../footer/footer.css';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import DropdownMenu from '../dropdown/dropdown';
import { Button, Modal, Row, Col, Form, Input, message, notification } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { addForm, loginUser } from "../../service/api";
import ForgotPassword from '../popups/forgotPassPopup/forgotPassPopup';
import axios from 'axios';
import useToken from '../../service/useToken';

import { FaFacebook, FaFacebookSquare, FaInstagram, FaInstagramSquare, FaLinkedin, FaLinkedinIn, FaTiktok, FaTwitter, FaTwitterSquare, FaYoutube, FaYoutubeSquare } from "react-icons/fa";

const Footer = () => {
    // const handleSubmit = async (values) => {
    //     console.log(values);
    //     values.address = JSON.stringify(values.address)
    //     values.phoneNumber = JSON.stringify(values.phoneNumber)
    //     values.emails = JSON.stringify(values.emails)
    //     values.website = JSON.stringify(values.website)
    //     values.image = imageData
    //     await addForm(values,data => {
    // debugger
    //       navigate('/devPage/' + data._id);
    //     });
    //   };




    const [messageApi, contextHolder] = message.useMessage();

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [isDataVisible, setIsDataVisible] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { token, saveLoginUser, removeToken } = useToken();
    const { id } = useParams();
    const location = useLocation();

    const showModal = () => {
        setOpen(true);
    };

    const logout = () => {
        debugger
        removeToken()

        navigate('/');
    }
    const handleOk = async (values) => {
        // debugger
        const { username, password } = values;
        try {
            setConfirmLoading(true);
            await loginUser(values, data => {
                debugger
                if (data.status == 200) {
                    let obj = data.data.result
                    saveLoginUser(obj);
                    setOpen(false);
                    setConfirmLoading(false);
                    navigate('/card/' + obj.hash);
                    messageApi.open({
                        type: 'success',
                        content: 'Login Successful!',
                    });


                } else {
                    notification.error({
                        message: `Notification`,
                        description: data.message,
                        placement: 'topRight',
                    });
                    setConfirmLoading(false);
                }

            });


            // const response = await axios.post('../../service/api', { username, password });
            // if (username === values.username) {
            //     setError('email dosent match!')
            // }
            // if (password === values.password) {
            //     setError('password doesnt match!')
            // }
            // if (response.data.success) {
            //     await addForm(values, data => {
            //         navigate('/card/' + data._id);
            //     });
            // } else {
            //     setError('Invalid email or password');
            // }
        } catch (error) {
            console.log(error);
            setError('Something went wrong. Please try again later.');
        }

        // setModalText('The modal will be closed after two seconds');
        // setIsDataVisible(!isDataVisible);
        // setConfirmLoading(true);
        // setTimeout(() => {
        //     setOpen(true);
        //     setConfirmLoading(false);
        // }, 2000);
    };


    useEffect(() => {
        console.log(location.pathname.indexOf('edit') > 0)
    }, [token]);
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    return (

        <>
            {location.pathname.indexOf('card') > 0 && <div style={{ textAlign: "center", paddingBottom: "5px" }}>
                <Link to={"/edit/" + id} className="edit-card-link">Edit this card</Link>
            </div>}
            <footer className='footer'>

                <div className='feedback'>

                    <ul className="footer-social-media">
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=100090184232006&mibextid=LQQJ4d" target="_blank">
                                <FaFacebookSquare size={20} />
                            </a>
                        </li>
                        <li>
                            <a href="https://mobile.twitter.com/emberL_Official" target="_blank">
                                <FaTwitterSquare size={20} />
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/emberlinkofficial?igshid=YmMyMTA2M2Y=" target="_blank">
                                <FaInstagramSquare size={20} />
                            </a>
                        </li>
                        <li>
                            <a href="https://youtube.com/@Ember-Link" target="_blank">
                                <FaYoutubeSquare size={20} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@ember_link" target="_blank">
                                <FaTiktok size={20} />
                            </a>
                        </li>
                    </ul>
                    {/* <p>Got feedback? Let us know.</p>
                    <a href="https://twitter.com/LinkVcard">@LinkVcard</a> */}
                </div>
                {/* <div className='language'>
                <label htmlFor="">Set language</label>
                <DropdownMenu />
            </div> */}
                {< Row >
                    {contextHolder}
                    <Col>
                        {!token ? <Button type="primary" onClick={showModal}>
                            Login
                        </Button>
                            : <Button type="primary" onClick={logout}>
                                Log Out
                            </Button>
                        }

                        <Modal
                            title="Login to Ember-Link"
                            open={open}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            confirmLoading={confirmLoading}
                            footer={null}
                        // footer={[
                        //     <Button key="back" onClick={handleCancel} style={{ color: "white", backgroundColor: "#5a6268" }}>
                        //         Cancel
                        //     </Button>,
                        //     <Button key="submit" type="primary" htmlType="submit" loading={confirmLoading}>
                        //         Submit
                        //     </Button>
                        // ]}

                        >
                            {!isDataVisible && (
                                <>
                                    <Form
                                        name="addAds"
                                        layout='vertical'
                                        onFinish={handleOk}
                                    >

                                        <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
                                            <Col span={6} className="label">
                                                <label htmlFor="" style={{ paddingLeft: "0", paddingRight: "0" }} className="label">
                                                    Email
                                                </label>
                                            </Col>
                                            <Col span={18} className="info-div">
                                                <Form.Item
                                                    name="email"
                                                    className="formItem-margins"
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Please input your E-mail!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Email address" className="info-input" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
                                            <Col span={6} className="label">
                                                <label htmlFor="" style={{ paddingLeft: "0", paddingRight: "0" }} className="label">
                                                    Password
                                                </label>
                                            </Col>
                                            <Col span={18} className="info-div">
                                                <Form.Item
                                                    name="password"
                                                    className="formItem-margins"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please Input password",
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password placeholder="Password" className="info-input" style={{ display: "flex" }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <ForgotPassword></ForgotPassword>

                                        <div style={{ textAlign: "right", padding: "10px", borderTop: "1px solid #ccc", marginTop: "5px" }}>
                                            <Button onClick={handleCancel} className="mr-2" style={{ color: "white", backgroundColor: "#5a6268", marginRight: "5px" }}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit" loading={confirmLoading}>
                                                Submit
                                            </Button>
                                            {/* <Button type="primary" htmlType="submit" className='ant-btn-44'>Save</Button> */}
                                        </div>
                                        {error && <div>{error}</div>}
                                    </Form>

                                </>
                            )}
                        </Modal>
                    </Col>
                </Row>}

            </footer >
        </>
    )
}
// {isDataVisible && (
// <div>
//     <p>Additional data/content goes here...</p>
// </div>
// )}

export default Footer;