import { Router, Routes, Route, Navigate } from 'react-router-dom';

import MainPage from "./pages/mainpage/mainPage";
import AnotherPage from './pages/anotherpage';
import { BrowserRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getTest } from './functions/test';
import EditPage from './pages/editpage/editPage';
import ImageLoader from './pages/image/image';

import DevPage from './pages/devpage/devPage';
import useToken from './service/useToken';
import PrivateRoutes from './service/PrivateRoute';

function App() {
  const { token } = useToken();
  useEffect(() => {
  }, [token]);

  return (
    <div className="App">
      {/* <h1>{data}</h1> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} ></Route>
          <Route path='/card/:id' element={<DevPage />} ></Route>
          {/* <Route path="/" element={<PrivateRoutes />}> */}
          <Route path='/edit/:id' element={<EditPage />} ></Route>
          {/* </Route> */}

        </Routes>
      </BrowserRouter>

      {/* <MainPage  /> */}
      {/* <DevPage /> */}

    </div>
  );
}

export default App;
