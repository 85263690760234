import { Button, Modal, Card } from 'antd';
import { FaQrcode } from 'react-icons/fa';
import { useState } from 'react';
import ModalQR from './modal';
import './qrCodePopup.css';

const QrCodePopUp = (props) => {
  return (
    <>
      <ModalQR value={props.value} />
    </>
  );
};
export default QrCodePopUp;