import { Image } from 'antd';
import { Link } from 'react-router-dom';
import '../header/header.css';
import  img  from '../../../src/photos/ember_link_black.png'
const Header = () => {
    return (
        <div class="header">
            <h1 className='paddingRemoved heading1-size'><Link to={"/"} class="header-link"><Image preview={false} src={img} ></Image></Link></h1>
            {/* <h3 className='paddingRemoved heading2-size'>Making since 2015</h3> */}
        </div>
    )
}

export default Header;