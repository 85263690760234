import { notification } from 'antd';
import axios from 'axios';
import interceptor from './interceptor';
const { REACT_APP_BASE_URL } = process.env


const URL = REACT_APP_BASE_URL;

export const addForm = async (data, callback) => {
    try {

        interceptor.axiosPost("Post", "/users/save_user", data).then(res => {
            callback(res)
        })

        // return await axios.post(`${URL}/users/save_user`, data).then((res) => {
        //     callback(res)
        // })
        //     .catch((error) => {
        //         console.log('Error while call add card api', error);
        //         return error.response
        //     });
    } catch (error) {
        console.log('Error while call add card api', error);
    }
}

export const updateCard = async (data, token, callback) => {

    try {
        interceptor.axiosPost("Post", "/users/updateUserDetail", data, token).then(res => {
            callback(res)
        })
        // return await axios.post(`${URL}/admin/updateUserDetail`, data)
    } catch (error) {
        console.log('Error while call add card api', error);
    }
}


export const getUserDetail = async (id, callback) => {
    try {
        return axios({
            method: 'Get',
            url: `${URL}/users/user_detail/${id}`,
        })
            .then((res) => {
                if (res.status == 200) { callback(res.data) }
            })
            .catch((error) => {
                return error.response
            })
    } catch (error) {
        console.log('Error while call add card api', error);
    }
}

export const loginUser = async (model, callback) => {
    try {
        interceptor.axiosPost("Post", "/auth/login", model).then(res => {
            callback(res)
        })
    } catch (error) {

        console.log('Error while call Login   api', error);
    }
}

export const imageLoad = async (id, callback) => {
    try {
        interceptor.axiosGet("Get", "/users/download/" + id).then(res => {
            callback(res)
        })
    } catch (error) {
        console.log('Error while call Login   api', error);
    }

}
export const forgotPassword = async (model, callback) => {
    try {
        interceptor.axiosPost("Post", "/auth/forgot_password", model).then(res => {
            callback(res)
        })
    } catch (error) {
        console.log('Error while call Login   api', error);
    }

}

export const resetPassword = async (model, callback) => {
    try {
        interceptor.axiosPost("Post", "/auth/reset_password/" + model.token, model).then(res => {
            callback(res)
        })
    } catch (error) {
        console.log('Error while call Login   api', error);
    }

}