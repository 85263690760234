import { Button, Modal, Card, Image, Avatar } from 'antd';
import { FaQrcode } from 'react-icons/fa';
import { useState, useEffect } from 'react';
// import QrCode from './qrCode';
import QRCode from 'react-qr-code';
import formDataa from '../../../vcard.json';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import './modal.css';
import { useParams } from 'react-router';
// const ModalQR = () => {
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   const showModal = () => {
//     setOpen(true);
//   };
//   const handleOk = () => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       setOpen(false);
//     }, 3000);
//   };
//   const handleCancel = () => {
//     setOpen(false);
//   };
//   return (
//     <>
//       <Button type="primary" className="qr-btn-style" icon={<FaQrcode />}  onClick={showModal}></Button>
//       <Modal
//         open={open}
//         title="Sacn QR Code"
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={
//           <Button className='close-btn-qr' key="back" onClick={handleCancel}>
//             Close
//           </Button>
//         }
//       >
//         <Card className='popup-card'>
//             <QrCode />
//         </Card>
//       </Modal>
//     </>
//   );
// };

const ModalQR = (props) => {
  console.log(props)
  const [qrCodeImage, setQRCodeImage] = useState(null);
  const [svg, setSvg] = useState(null);
  console.log(props.value)
  const { id } = useParams()
  const baseUrl = "http://localhost:3000/devPage/";
  const url = `${baseUrl}${id}`;
  console.log(url);

  htmlToImage.toPng(document.getElementById('qr-code'))
    .then(function (dataUrl) {
      setQRCodeImage(dataUrl);
    });

  useEffect(() => {


    // getUserDetail(id, data => {
    //     setformData(data)
    // });
    // formDataa.emails = JSON.parse(formDataa?.emails);
  }, [])
  const [modal1Visible, setModal1Visible] = useState(false);
  // const [modal2Visible, setModal2Visible] = useState(false);
  const downloadQR = () => {
    svg.toDataURL((data) => {
      debugger
      const shareImageBase64 = {
        title: "QR",
        message: "Ehi, this is my QR code",
        url: `data:image/png;base64,${data}`
      };
    });
  };
  return (
    <div className=''>
      <Button className='button-share-qr' onClick={() => setModal1Visible(true)} icon={<FaQrcode />}></Button>
      {/* <Button onClick={() => setModal2Visible(true)}>Open Modal 2</Button> */}

      <Modal
        title="Qr Code"
        visible={modal1Visible}
        onCancel={() => setModal1Visible(false)}
        footer={
          <Button className='close-btn-qr' key="back" onClick={() => setModal1Visible(false)}>
            Close
          </Button>
        }
      >
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
          {/* <QRCode
            id='qr-code'
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={url}
            level={"H"}
            viewBox={`0 0 256 256`}
            getRef={(ref) => {
              console.log(ref)
              (setSvg(ref))}}
          /> */}
          <Image preview={false} src={props?.value?.qrcode} width={200}></Image>
          {/* <a onClick={downloadQR}> Download QR </a> */}
        </div>


      </Modal>

      {/* <Modal
        title="Modal 2"
        visible={modal2Visible}
        onCancel={() => setModal2Visible(false)}
      >
        <p>Content of Modal 2</p>
      </Modal> */}
    </div>

  )
}
export default ModalQR;