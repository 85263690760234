import { Button, Modal, Card } from 'antd';
import { FaQrcode } from 'react-icons/fa';
import { useState } from 'react';
import ModalShare from './shareModal';
import './sharePopup.css';

const SharePopUp = () => {
  return (
    <>
      <ModalShare />
    </>
  );
};
export default SharePopUp;