import { useLocation } from 'react-router-dom';

function AnotherPage() {
  const { state } = useLocation();
  const formData = state.formData;
  const name = formData.get('name');
  const email = formData.get('email');

  return (
    <div>
      <h2>Form Data:</h2>
      <pre>{JSON.stringify(state.formData, null, 2)}</pre>
      <h1>Form Data</h1>
      <p>Name: {name}</p>
      <p>Email: {email}</p>
    </div>
  );
}
export default AnotherPage;